<template>
    <b-card>
  
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img ref="previewEl"
              src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
              height="100" />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>
  
        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class="mb-75 mr-75 mt-2"
            @click="$refs.refInputEl.$el.click()">
            Upload New Image
          </b-button>
          <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
            @input="inputImageRenderer" />
          <!--/ upload button -->
  
          <!-- reset -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="md"
            class="mb-75 mr-75 mt-2" @click.prevent="resetImage">
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->
  
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group label="First Name" label-for="account-username">
              <b-form-input v-model="firstname" placeholder="First Name" name="username" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Last Name" label-for="account-name">
              <b-form-input v-model="lastname" name="name" placeholder="Last Name" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Phone Number" label-for="account-phone">
              <b-form-input v-model="phone" name="Phone" type="number" placeholder="Phone" />
  
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input v-model="email" name="email" placeholder="Email" />
  
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Address" label-for="account-address">
              <b-form-input v-model="address" name="address" placeholder="Address" />
  
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Zip Code" label-for="account-zipcode">
              <b-form-input v-model="zipcode" name="zipcode" placeholder="Zip Code" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Country" label-for="account-country">
              <b-form-select v-model="selectedCountry" :options="countries" placeholder="Select a country"></b-form-select>
            </b-form-group>
          </b-col>
  
          <!-- alert -->
          <!-- <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col> -->
          <!--/ alert -->
  
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
              Save changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
              @click.prevent="resetForm">
              Cancle
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </template>
  
  <script>
  import {
    BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BFormSelect, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  // import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  // import { ref } from '@vue/composition-api'
  
  export default {
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BAlert,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
      BFormSelect
  
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        formData: {
          profileFile: null,
          img: '',
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          address: '',
          zipcode: '',
          selectedCountry:null,
          countries: [
            { text: 'United States', value: 'US' },
            { text: 'United Kingdom', value: 'UK' },
          ]
        },
      }
    },
    methods: {
      async saveChanges() {
        const apiUrl = 'https://example.com/your-api-endpoint';
        await axios
          .post(apiUrl, this.formData)
          .then(response => {
          })
          .catch(error => {
            console.error('Error saving data:', error);
          });
      },
      inputImageRenderer() {
  
      },
      uploadImage() {
        this.$refs.refInputEl.$el.click();
      },
      resetImage() {
        this.profileFile = '';
      },
      resetForm() {
        this.firstname = '';
        this.lastname = '';
        this.phone = '';
        this.email = '';
        this.address = '';
        this.zipcode = '';
        this.selectedCountry = '';
      },
      submit() {
  
      },
    }
  }
  </script>
  